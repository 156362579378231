@import '@davita/bridge-library/scss/davita.scss';
@import '@davita/bridge-library/scss/font-icons.scss';

body {
  padding-top: env(safe-area-inset-top) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
  height: 100vh !important;
  overflow: hidden !important;
}

// Nutrition subnav: It doesn't work in Nutrition application. It has to be root css (style.scss of UPP)
mat-ink-bar.mat-ink-bar {
  background-color: #00a8e4;
}

.mat-drawer.mat-drawer-side {
  border: none;
}

mat-sidenav-container {
  height: 100vh;
}

.di-anatomykidney {
  color: $light-color-red !important;

  @media (prefers-color-scheme: dark) {
    color: $dark-color-red !important;
  }
}

.di-technology18 {
  color: $light-color-blue !important;

  @media (prefers-color-scheme: dark) {
    color: $dark-color-blue !important;
  }
}

.di-computer01 {
  color: $light-color-purple !important;

  @media (prefers-color-scheme: dark) {
    color: $dark-color-purple !important;
  }
}

.di-unie45a {
  color: $light-color-yellow !important;

  @media (prefers-color-scheme: dark) {
    color: $dark-color-yellow !important;
  }
}

.di-graph06 {
  color: $light-color-green !important;

  @media (prefers-color-scheme: dark) {
    color: $dark-color-green !important;
  }
}

.di-medicalbottle02 {
  color: $light-color-teal !important;

  @media (prefers-color-scheme: dark) {
    color: $dark-color-teal !important;
  }
}

.di-hospital34 {
  color: $light-color-purple !important;

  @media (prefers-color-scheme: dark) {
    color: $dark-color-purple !important;
  }
}

.di-people {
  color: $light-color-yellow-dark !important;

  @media (prefers-color-scheme: dark) {
    color: $dark-color-yellow-dark !important;
  }
}

bri-empty-state {
  .empty-state-header {
    margin: 0px !important;
  }
}

bri-search-header {
  .search-header-cover-image {
    background-position: top !important;
  }

  //TODO: modality specific css
  // .search-header-sub-text-label {
  //   text-align: left !important;
  // }
}

.davita-snack-bar.mobile {
  padding-top: env(safe-area-inset-top) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}

.delete-plan-modal {
  &_container {
    padding-top: env(safe-area-inset-top) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;
  }
}

#graduate-dialog.mat-mdc-dialog-container {
  max-height: 90vh !important;
}
